* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header {
    background-color: #c0b2a3;
    color: #324362;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
}

header h1 {
    font-size: 40px;
    font-family: 'Caveat', cursive;
}

header nav ul {
    display: flex;
    gap: 10px;
    list-style: none;
}

header nav ul li {
    font-size: 20px;
    cursor: pointer;
    font-family: 'Caveat', cursive;
}
header nav ul li:hover {
    color: #F4E7A5;
}

/* .active {
    color: #F4E7A5;
} */

/* footer */
footer {
    background-color: #c0b2a3;
    color: #324362;
    padding: 20px;
    text-align: center;
}

footer a {
    color: #324362;
    font-size: 25px;
    margin-left: 15px;
}

footer a:hover {
    color: #F4E7A5;
}

/* main */
main {
    min-height: 80vh;
    background-color: #F6F2EE;
    padding-top: 15px;
    padding-bottom: 15px;
}

/* about page */

.about-page {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.about-image {
    width: 50%;
    background-color: #f6f2ee;
    padding: 25px;
}

.about-image img {
    width: 90%;
    padding: 1px;
    height: 100%;
}

.about-details {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ebe2da;
    font-family: 'Caveat', cursive;
    padding: 20px;
}

.about-details p {
    font-size: 22px;
    line-height: 2;
}

/* portfolio page */

.portfolio-page {
    padding: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    background-color: #F6F2EE;
}

.project {
    width: 30%;
    border: 1px solid #ccc;
    box-shadow: 5px 10px #ccc;
}

.project img {
    width: 100%;
    height: 400px;
}

.project div {
    display: flex;
    justify-content: space-between;
}

.project a {
    display: inline-block;
    width: 49%;
    background-color: #c0b2a3;
    text-align: center;
    text-decoration: none;
    padding: 10px;
    color: #5B699C;
}

.project a:hover {
    background-color: #F4E7A5;
}

/* forms */
.contact-page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
    background-color: #F6F2EE;
}

form,
.contact-page h2 {
    max-width: 400px;
}

input,
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #c0b2a3;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type=submit] {
    width: 100%;
    background-color: #c0b2a3;
    color: #5B699C;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #F4E7A5;
}

textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}

/* resume */

.skills {
    font-family: 'Rajdhani', sans-serif;
    background-color: black;
    color: #F6F2EE;
    width: 30%;
    display: block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    min-height: 500px;
    line-height: 2;
    margin: auto;
    padding: 20px;
}

.about-resume h3 {
    text-align: center;
    font-size: 30px;
}

.resume-button {
    display: block;
    width: 200px;
    margin: auto;
    /* margin-top: 15px; */
    text-decoration: none;
    background-color: #c0b2a3;
    color: #5B699C;
    padding: 10px;
    text-align: center;
}

.resume-button:hover {
    background-color: #F4E7A5;
}

@media (max-width:450px)  {
    .header nav ul li {
        color: #324362;
}
}

@media (max-width:450px) {
    header {
        color: #F4E7A5
    }
}

@media (max-width:720px) {
    .project {
        width: 49%;
    }
}

@media (max-width:640px) {

    header h1,
    header nav {
        width: 100%;
    }

    header h1 {
        padding-left: 20px;
    }

    header {
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 0;
    }

    nav {
        background-color: rgb(58, 57, 57);
        padding: 10px 0;
        padding-right: 10px;
    }

    nav ul {
        justify-content: flex-end;
    }

    .about-image,
    .about-details {
        width: 100%;
    }

   .skills {
    width:100%;
   }
}